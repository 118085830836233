import React, { ChangeEvent, FormEvent, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Typography, makeStyles, TextField, Grid, Button, InputLabel, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';


const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 3.5em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1.5em 0 2em',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export default function RoomNameScreen({ name, roomName, setName, setRoomName, handleSubmit }: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const search = useLocation().search;
  // const [candidateEmail, setEmail] = useState<string>(user?.displayName || (new URLSearchParams(search).get('candidateEmail') ?? ''));
  // const [recuiterEmail, setRecuiterEmail] = useState<string>(user?.displayName || (new URLSearchParams(search).get('interviewerEmail') ?? ''));
  // const [employeeEmail, setemployeeEmail] = useState<string>(user?.displayName || (new URLSearchParams(search).get('employerEmail') ?? ''));
  
  let candidate = search.includes('candidateEmail')
  let employerEmail = search.includes('employerEmail')
  let interviewerEmail = search.includes('interviewerEmail')
  let Name = search.includes('name')



  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };

  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Join a Room
      </Typography>
      <Typography variant="body1">
        {/* {hasUsername
          ? "Enter the name of a room you'd like to join."
          : "Enter your name and the name of a room you'd like to join"} */}
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <InputLabel shrink htmlFor="input-user-name">
        {employerEmail? "Enter Your Email/ Name": "Enter Your Email"}       
              </InputLabel>
              {candidate ? <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                // onChange={handleNameChange}
              /> : null
              }
       
            {employerEmail? <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              /> : null}
              {Name? <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                onChange={handleNameChange}
              /> : null}
              {
                interviewerEmail? <TextField
                id="input-user-name"
                variant="outlined"
                fullWidth
                size="small"
                value={name}
                // onChange={handleNameChange}
              />   : null
              }
              {
                candidate?<p>
                          <sup style={{ color: "red" }}>*</sup>
                          <b>Note:</b>Email not editable
                        </p>:null
              }
              
            </div>
          )}
          <div className={classes.textFieldContainer}>
            {/* <InputLabel shrink htmlFor="input-room-name">
              Room Name
            </InputLabel>
            <TextField
              autoCapitalize="false"
              id="input-room-name"
              variant="outlined"
              fullWidth
              size="small"
              value={roomName}
              onChange={handleRoomNameChange}
            /> */}
          </div>
        </div>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            type="submit"
            color="primary"
            // disabled={ !name || roomName}
            className={classes.continueButton}
          >
            Continue
          </Button>
        </Grid>
      </form>
    </>
  );
}
