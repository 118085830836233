import React, { useState, useEffect } from 'react';

export default function ClockTime() {
  const [currentTime, setCurrentTime] = useState();
  let hr = 0,
    min = 0,
    sec = 0;
  const UpdateTime = () => {
    let time;
    sec = sec + 1;

    if (sec == 60) {
      min = min + 1;
      sec = 0;
    }
    if (min == 60) {
      hr = hr + 1;
      min = 0;
      sec = 0;
    }

    time = (hr >= 10 ? hr : '0' + hr) + ':' + (min >= 10 ? min : '0' + min) + ':' + (sec >= 10 ? sec : '0' + sec);

    console.log('time', time);
    setCurrentTime(time);
  };
  const getData = () => {
    setInterval(() => {
      UpdateTime();
    }, 1000);
  };
  useEffect(() => {
    getData();
  }, []);

  return <div>{currentTime}</div>;
}
